<template>
  <div>
    <a-modal v-model:visible="visible" title="新增菜单" @ok="handleOk" okText="新增" cancelText="取消" :afterClose="closeModal" width="30vw">
      <a-form :model="formState" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-item label="主菜单">
          {{record.parentName}}
        </a-form-item>
        <a-form-item label="菜单">
          <a-input v-model:value="data.name" />
        </a-form-item>
        <a-form-item label="权限">
          <permissionSelector v-model="data.permission" />
        </a-form-item>
      </a-form>
      <div style="text-align: center;margin-top: 20px;">
        <a-button type="primary" @click="visible2=true">新增权限</a-button>
      </div>
    </a-modal>
  </div>
  <addPermission v-model="visible2" />
</template>

<script>
import { reactive } from "vue";
import { Add } from "/src/api/menu.js";
import permissionSelector from '/src/components/selector/permissionSelector.vue';
import addPermission from '/src/components/permission/addPermission.vue';
export default {
  components: {
    permissionSelector,
    addPermission
  },
  data() {
    return {
      visible: this.modelValue,
      visible2: false,
      medicine: "",
      data: {
        name: undefined,
        permission: undefined,
      },
      labelCol: {
        span: 6,
      },
      wrapperCol: {
        span: 14,
      },
    };
  },
  emits: ['update:modelValue'],
  created() {

  },
  mounted() {
  },
  activated() {
  },
  //获取父元素传值
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    record: {
      type: Object,
      default: {}
    },
  },
  computed: {

  },
  methods: {

    async handleOk() {
      let _this = this;

      let res = await Add({
        ...this.data,
        parentId: this.record.parentId,
        parentName: this.record.parentName,
        sequence: this.record.sequence,
        // sequence: "10000",
        clientAppName: this.record.clientAppName,
        isRoot: false,
        type: "菜单"
      })
      if (res.errCode == 0) {
        _this.$message.success("新建成功", function () { });
        _this.visible = false
        this.data = {
          name: undefined,
          permission: undefined,
        }
      }
    },
    closeModal() {
      this.$emit('update:modelValue', false);
    }
    //初始化data所有数据，并关闭modal
  },
  watch: {
    modelValue(val) {
      this.visible = val
    },
  }
};
</script>

<style scoped>
.head {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  align-items: center;
}
.head span {
  font-weight: bold;
  margin-right: 10px;
}
</style>
