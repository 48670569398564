<template>
  <div>
    <div>
      <div class="searchArea">
        <!-- <a-range-picker v-model:value="inputs.patroltime" :placeholder="['应巡开始时间','应巡结束时间']" :locale="locale" /> -->
        <a-select v-model:value="inputs.clientAppName" placeholder="客户端名称">
          <!-- <a-select-option v-for="item in $store.state.areaNameType" :value="item.NAME">{{ item.NAME }}</a-select-option> -->
          <a-select-option value="智慧中医">智慧中医</a-select-option>
          <a-select-option value="慧中医">慧中医</a-select-option>
          <a-select-option value="世道医护">世道医护</a-select-option>
        </a-select>
        <a-button type="primary" shape="circle" @click="search('')">
          <template #icon>
            <SearchOutlined />
          </template>
        </a-button>
        <!-- <a-button style="margin-left: 10px" @click="resetForm">重置</a-button> -->
        <a-button style="margin-left: 10px" type="primary" @click="addParentMenu(record)">
          <template #icon>
            <PlusOutlined />
          </template>
          新增主菜单
        </a-button>
      </div>

      <a-table class="ant-table-striped" :columns="columns" :data-source="data" bordered rowKey="id" :pagination="false" :scroll="scroll" @change="search">

        <template #operation="{ record }">
          <div class="flex_sa" v-if="record?.name">
            <div class="editable-row-operations flex_sa">
              <span v-if="editableData[record.id]">
                <a @click="save(record.id)">保存</a>
              </span>
              <span v-if="editableData[record.id]">
                <a @click="cancel(record.id)">取消</a>
              </span>
              <span v-else>
                <a @click="edit(record.id)">编辑</a>
              </span>
            </div>
            <div class="editable-row-operations">
              <span>
                <a-popconfirm title="是否确认删除" @confirm="onDelete(record.id)" okText="确认" cancelText="取消">
                  <a>删除</a>
                </a-popconfirm>
              </span>
            </div>
          </div>
        </template>
        <template v-for="col in ['name','routePath','source']" #[col]="{ text, record }" :key="col">
          <div>
            <!-- <div>{{record.id}}</div> -->
            <a-input v-if="editableData[record.id]" v-model:value="editableData[record.id][col]" style="margin: -5px 0" />
            <template v-else>{{ text }}</template>
          </div>
        </template>
        <template v-for="col in ['parentName']" #[col]="{ text, record }" :key="col">
          <div class="hover">
            {{ text }}
            <div class="btn">
              <plus-circle-outlined @click="addMenu(record)" style="color:#34e073;" />
              <form-outlined @click="editParentMenu(record)" style="color:#40a9ff;" />
            </div>
          </div>
        </template>
      </a-table>

    </div>

    <!-- 新增 -->
    <addParentMenu v-model="visible" :record="selectedRow" />
    <addMenu v-model="visible2" :record="selectedRow" />
    <editParentMenu v-model="visible3" :record="selectedRow" />

  </div>
</template>


<style scoped>
.online {
  background: #40a9ff;
  border-color: #40a9ff;
}

.offline {
  background: #979596;
  border-color: #979596;
}

:deep(.ant-table-tbody > tr > td, .ant-table-thead > tr > th) {
  padding: 10px 8px;
}

:deep(.ant-table-tbody
    > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
    > td) {
  background: initial;
}
.hover:hover .btn {
  display: block;
}
.btn {
  display: none;
}
.btn > span {
  display: inline-block;
  margin: 10px;
  cursor: pointer;
}
</style>


<script>
import {
  SearchOutlined,
  PlusOutlined,
  PlusCircleOutlined,
  FormOutlined
} from "@ant-design/icons-vue";
import { reactive } from "vue";
import { cloneDeep } from "lodash-es";
import locale from "ant-design-vue/es/date-picker/locale/zh_CN";
import { Search, Update, Delete } from "/src/api/menu.js";
import addMenu from '/src/components/menu/addMenu.vue';
import addParentMenu from '/src/components/menu/addParentMenu.vue';
import editParentMenu from '/src/components/menu/editParentMenu.vue';


export default {
  components: {
    SearchOutlined,
    PlusOutlined,
    locale,
    addMenu,
    PlusCircleOutlined,
    FormOutlined,
    addParentMenu,
    editParentMenu
  },
  name: "菜单管理",
  data() {
    return {
      modalBtn: "",
      modalIndex: "0",
      pageSize: 12,
      visible: false,
      visible2: false,
      visible3: false,
      scroll: {},
      active: undefined,
      id: undefined,
      total: 0,
      current: 1,
      inputs: {
        name: undefined,
        clientAppName: "智慧中医"
      },
      selectedClientAppName: "",
      // currentClientAppName:"智慧中医",
      labelCol: {
        span: 6,
      },
      wrapperCol: {
        span: 14,
      },
      columns: [
        {
          title: "主菜单",
          key: "parentName",
          dataIndex: "parentName",
          width: "25%",
          slots: {
            customRender: "parentName"
          },
          customCell: (record, rowIndex, column) => {
            let parentName = record.parentName;
            let index = this.data.findIndex(item => item.parentName == parentName)
            let count = this.data.filter(item => item.parentName == parentName).length;
            if (index == rowIndex) {
              // console.log("设置第:" + rowIndex + "行为" + count + "行");
              return {
                rowSpan: count
              };
            } else {
              return {
                style: {
                  "display": "none"
                }
              };
            }
          }
        },
        {
          title: "菜单",
          key: "name",
          dataIndex: "name",
          width: "25%",
          slots: {
            customRender: "name"
          }
        },
        {
          title: "路径",
          key: "routePath",
          dataIndex: "routePath",
          width: "25%",
          slots: {
            customRender: "routePath"
          }
        },

        // {
        //   title: "首字母",
        //   key: "nameInitial",
        //   dataIndex: "nameInitial",
        //   width: "25%",
        //   slots: {
        //     customRender: "nameInitial"
        //   }
        // },
        {
          title: "操作",
          dataIndex: "operation",
          width: "20%",
          slots: {
            customRender: "operation"
          }
        }
      ],
      data: [],
      rowSelection: {
        onChange: (selectedRowKeys, selectedRows) => {
          console.log(
            `selectedRowKeys: ${selectedRowKeys}`,
            "selectedRows: ",
            selectedRows
          );
          this.selectedRows = selectedRows;
        },
        onSelect: (record, selected, selectedRows) => {
          console.log(record, selected, selectedRows);
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
          console.log(selected, selectedRows, changeRows);
        }
      },
      editableData: reactive({}),
      selectedRows: {},
      selectedRow: {},
      // doExcel: doExcel
    };
  },
  created() { },
  mounted() {
    this.search("");
  },
  activated() {
    if (localStorage.getItem("refrashProject") == "true") {
      // this.search(1);
    }
    localStorage.setItem("refrashProject", false);
  },
  methods: {
    // 获取处方详情

    // 打开编辑处方

    resetForm() {
      this.inputs = {
        name: undefined,
      }
    },
    addParentMenu(record) {
      this.selectedRow = { ...record, clientAppName: this.selectedClientAppName };
      this.visible = true;
    },
    addMenu(record) {
      this.selectedRow = { ...record, clientAppName: this.selectedClientAppName };
      this.visible2 = true;
    },
    editParentMenu(record) {
      this.selectedRow = { ...record, clientAppName: this.selectedClientAppName };
      this.visible3 = true;
    },
    async onDelete(id) {
      this.editableData = {}
      let res = await Delete({ id })
      if (res.errCode == 0) {
        // this.data = this.data.filter(function (item) {
        //   return item.id !== id;
        // });
        this.search()
      } else {
        this.$message.error("删除失败", function () { });
      }
    },
    edit(id) {
      let _data = this.data;
      this.editableData[id] = cloneDeep(
        _data.filter(item => id === item.id)[0]
      );
    },
    async save(id) {
      let _data = this.data;

      //判断editableData和修改前是否一致，如果一致则不修改
      if (JSON.stringify(this.data.find((item) => item.id == id)) === JSON.stringify(this.editableData[id])) {
        // console.log("未修改")
        delete this.editableData[id];
        return;
      }
      let res = await Update(this.editableData[id])
      if (res.errCode == 0) {
        Object.assign(
          _data.filter(item => id === item.id)[0],
          this.editableData[id]
        );
        // console.log(JSON.stringify(this.editableData[id]))
        // console.log(this.editableData[id])
        delete this.editableData[id];
      }
    },

    cancel(id) {
      delete this.editableData[id];
    },

    tabsName(tabName) {
      this.$store.commit("changePage", { name: tabName.name, query: tabName.query });
    },
    async search(pagination) {
      let _this = this;
      this.selectedClientAppName = this.inputs.clientAppName
      this.editableData = {}
      // this.currentClientAppName = this.inputs.clientAppName
      let params = {
        name: this.inputs.name,
        pageSize: this.pageSize,
        clientAppName: this.inputs.clientAppName,
      };
      if (pagination == "") {
        params.current = 1
      } else {
        params.current = pagination.current
        this.current = pagination.current;
      }
      let res = await Search(params)
      if (res.errCode == 0) {
        if (pagination == "") {
          // this.$message.success("查询成功", function () { });
          this.current = 1;
        }
        this.total = res.data.total;
        let data = []
        res.data.forEach(element => {
          if (element?.subMenus?.length > 0) {
            element.subMenus.forEach(element2 => {
              element2.parentName = element.name
              element2.parentId = element.id
            })
            data.push(...element.subMenus)
          } else {
            data.push({ parentName: element.name, parentId: element.id })
          }

        });
        this.data = data

        if (data.length > 12) {
          this.scroll = { y: 650 }
        } else {
          this.scroll = {}
        }
      } else {
        this.$message.error("查询失败", function () { });
      }
      // this.data = res
    },
  }
};
</script>